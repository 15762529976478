import Vue from 'vue'
import VueRouter, { RouterLink } from 'vue-router'
import Home from "../views/main/main.vue";

Vue.use(VueRouter)
// "vue-router": "^3.5.1",
const routes = [
  {
    path:'/instructions',
    component:()=>import('../views/instructions/instructions.vue')
  },
  {
    path:'/warehouse',
    component:()=>import('../views/warehouse/warehouse.vue')
  },
  {
    path: '/',
    // redirect:'/',
    component:()=>import('../views/main/main.vue'),
    children:[
      // videodata
      {
        path:'fourway',
        meta:{
          title:'四路景观灯'
        },
        component:()=>import('../views/fourway/fourway.vue')
      },
      {
        path:'videodata',
        meta:{
          title:'视频监控'
        },
        component:()=>import('../views/videodata/videodata.vue')
      },
      {
        path:'datavisual',
        meta:{
          title:'数据展示'
        },
        component:()=>import('../views/datavisual/datavisual.vue')
      },
      {
        path:'airswitch',
        meta:{
          title:'空开网关'
        },
        component:()=>import('../views/airswitch/airswitch.vue')
      },
      {
        path:'eightcont',
        meta:{
          title:'八路集控器'
        },
        component:()=>import('../views/eightcont/eightcont.vue')
      },
      {
        path:'map',
        meta:{
          title:'地图'
        },
        component:()=>import('../views/map/map.vue')
      },
      {
        path:'controller',
        meta:{
          title:'集控器数据'
        },
        component:()=>import('../views/controller/controller.vue')

      },
      {
        path:'controlmap',
        meta:{
          title:'集控器地图'
        },
        component:()=>import('../views/controlmap/controlmap.vue')

      },
      {
        path:'control',
        meta:{
          title:'策略控制'
        },
        component:()=>import('../views/control/control.vue')

      },
      {
        path:'lamptests',
        meta:{
          title:'单灯数据测试'
        },
        component:()=>import('../views/lamptests/lamptests.vue')

      },
      {
        path:'singlelamp',
        meta:{
          title:'单灯数据'
        },
        component:()=>import('../views/singlelamp/singlelamp.vue')

      },
      {
        path:'historydata',
        meta:{
          title:'单灯历史数据'
        },
        component:()=>import('../views/historydata/historydata.vue')

      },
      {
        path:'strategy',
        meta:{
          title:'策略设置'
        },
        component:()=>import('../views/strategy/strategy.vue')

      },
      {
        path:'operatLogs',
        meta:{
          title:'操作日志'
        },
        component:()=>import('../views/operatLogs/operatLogs.vue')

      },
      {
        path:'devrecord',
        meta:{
          title:'设备日志'
        },
        component:()=>import('../views/devrecord/devrecord.vue')

      },
      {
        path:'IoTnetworkcard',
        meta:{
          title:'物联网卡'
        },
        component:()=>import('../views/IoTnetworkcard/IoTnetworkcard.vue')

      },
      {
        path:'warning',
        meta:{
          title:'报警信息'
        },
        component:()=>import('../views/warning/warning.vue')

      },
    ]
  },
  {
    path: '/system',
    // redirect:'noredirect',
    component:Home,
    meta: {
      title: '系统管理',
    },
    children:[
      {
        path:'productmanage',
        meta:{
          title:'产品管理'
        },
        component:()=>import('../views/productmanage/productmanage.vue')

      },
      {
        path:'usermanage',
        meta:{
          title:'用户管理'
        },
        component:()=>import('../views/usermanage/usermanage.vue')

      },
      {
        path:'rolemanage',
        meta:{
          title:'角色管理'
        },
        component:()=>import('../views/rolemanage/rolemanage.vue')

      },
      {
        path:'menumanage',
        meta:{
          title:'菜单管理'
        },
        component:()=>import('../views/menumanage/menumanage.vue')

      },
    ]
  },
  {
    path:'/login',
    component:()=>import('../views/login/login.vue')

  },
]

const router = new VueRouter({
  // history模式，需要和服务端配合才能在生产环境下正常使用
  mode: 'history',
  routes
})
// 全局前置守卫
router.beforeEach((to,from,next)=>{

  if(to.path == '/login'){  //访问登录页
    next();
    return;
  }


  if(to.path == '/warehouse'){ //访问仓库页
    next();
    return;
  
  }
  if(to.path == '/instructions'){ //说明
    next();
    return;
  }
  // 获取登录用户信息
  let userobj = localStorage['userInfo']?JSON.parse(localStorage['userInfo']):'';
  let userPathArr =sessionStorage['usermenu']? JSON.parse(sessionStorage['usermenu']):[];
  // 判断用户是否登录
  if(userobj==''){
    next('/login')
  }
  else{
    let allPathArr = ['/', '/login','/map','/instructions',...userPathArr];
    if (allPathArr.indexOf(to.path) == -1) {
      next('/login'); //没有权限,去登陆
    } else {
      next(); //有权限,放行
    }
  
  }
// 用户登录
  // let userPathArr =JSON.parse(sessionStorage['usermenu']);

  
 
})
// 全局前置路由
// 挂载路由导航守卫
// to 将要访问的路径
// from 从哪个路径跳转而来
// next 是个函数，表示放行 next() 放行  next('/login') 强制跳转

export default router
