<template>
    <div>
        <el-dialog title="请输入安全操作密码" :visible.sync="toshowDia" @close="closeDia" append-to-body width="40%">
            <el-form>
                <el-form-item label="安全操作码">
                    <el-input v-model="form.password" autocomplete="off" show-password style="width: 70%"></el-input>
                </el-form-item>
                <div class="button_box">
                    <div style="flex: 1"></div>
                    <el-button @click="closeDia">取消</el-button>
                    <el-button type="primary" @click="toSureCode">确认</el-button>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>
 
<script>
export default {
    name: 'saveCode',
    data() {
        return {
            toshowDia: false,
          
            form: {
                password: ''
            },
            showInit: 0
        };
    },
    props: {},
    watch: {},
    methods: {
        // 取消
        closeDia() {},
        toSureCode() {},
        // toSureCode1() {
        //     checkauth(this.form).then(d=>{
        //         if(d.code == '0000' && d.data == true){
        //             successmsg('安全码正确')
        //             this.toshowDia = false
        //         }else{
        //             failmsg('安全码错误，操作失败')
        //         }
        //     }).catch(e=>{
        //         console.log('安全认证接口调用失败');
        //     })
           
        // },
    },
    created() {
 
    }
};
</script>
 
<style scoped>
.button_box {
    display: flex;
    align-items: center;
    margin-top: 20px;
}
.attention_word{
    font-size: 16px;
    color: #333;
}
.attention_word span{
    font-size: 16px;
    color: #449ef9;
}
</style>