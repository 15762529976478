export default{
    inserted(el, binding, vnode) {
    /*el: 指令所绑定的元素，可以用来直接操作DOM。
       binding: 一个对象，包含指令的很多信息。
       vnode: Vue编译生成的虚拟节点。*/
      const { value } = binding
      // 自定义指令的执行函数，取到登录缓存的按钮权限数组，赋值给全局对象userMsg
      global.ButtonPermissions = JSON.parse(sessionStorage.getItem('ButtonPermissions'))
      if (global.ButtonPermissions && global.ButtonPermissions.indexOf(value) < 0) {
        el.style.display = 'none'
      }
    }
  }
  