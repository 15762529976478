import Vue from 'vue'
import App from './App.vue'
import router from './router'
import BaiduMap from 'vue-baidu-map-v3'
import axios from 'axios'
import JSONBIG from 'json-bigint'

import store from './store'
import screenfull from "screenfull";

// import "vue-easytable/libs/theme-default/index.css";
import Echart from './components/echart/index.vue'
import ItemWrap from './components/item-wrap/item-wrap.vue'
import Message from './components/message/message.vue'
import Reacquire from './components/reacquire/reacquire.vue'
import Messages from './components/message/message'
import  '@/assets/css/public.scss'
// import "@/assets/css/index.scss"


import './directives/directives' // 为弹窗移动

// 引入ezuikit-js
// import EZUIKit from 'ezuikit-js';
// Vue.use(EZUIKit);

// 百度地图3.0方法2
// import BaiduMap from '@/components/map/index'

// 全局数字过滤
import * as filters from '@/directives/filters'

// 自定义组件
Vue.component("Echart",Echart)
Vue.component("ItemWrap",ItemWrap)
Vue.component("Message",Message)
Vue.component("Reacquire",Reacquire)
Vue.prototype.$Message =  Messages

// datav
import {loading,borderBox13,digitalFlop,capsuleChart,borderBox8} from '@jiaminghi/data-view'

// 配置请求的基准URL地址
// axios.defaults.baseURL = "http://192.168.0.100:8000";
axios.defaults.baseURL ="https://leipeide.cloud/api/"

axios.defaults.transformResponse = [
  function (data) {
    const json = JSONBIG({
      storeAsString: true
    })
    const res = json.parse(data)
    return res
  }
]
// 百度地图
Vue.use(BaiduMap, {
  // hpk9mjInCB0TD8KV0w5cYO8ob9a95S9x
  ak: 'HehA5EGLPi7NWLgk40fFvgKrsLzQt3F6'
})

// 时间转换
import moment from "moment";
Vue.prototype.$moment = moment;

// datav组件
Vue.use(loading)
Vue.use(borderBox13)
Vue.use(borderBox8)
Vue.use(digitalFlop)
Vue.use(capsuleChart)


// 引入elementui框架
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
Vue.use(ElementUI);

// 全局注册
import ChartBlock from '../src/components/Charts/ChartsBlock.vue'
Vue.use(ChartBlock)

// 引入全局样式文件
import './assets/css/reset.css'
// 引入echarts主题文件
import "../public/static/theme/vintage"

// 引入echarts 挂载到vue原型链
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts;

// 引入axios挂载到vue原型链
// Vue.prototype.$http = axios;

// 权限
import Permission from './directive/permission'
Vue.use(Permission)

// 密码输入框
import saveCode from '@/components/common/saveCode.js';
Vue.use(saveCode);

// 全局数据过滤器
Object.keys(filters).forEach(k => Vue.filter(k, filters[k]));

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
