import MmToast from './Savecode.vue';
import { Message } from 'element-ui';
import Router from '../../router/index.js';
import { checkauth } from "../../request/api";
import { successmsg,failmsg } from "../../tools/tools";


let instance;
let showToast = false;
const saveCode = {
    install(Vue, options = {}) {
        let opt = MmToast.data(); // 获取组件中的默认配置
        Object.assign(opt, options); // 合并配置,最终返回opt以一个对象的形式
        Vue.prototype.$saveCode = (message) => {
            return new Promise((resolve, reject) => {
                if (message) {
                    opt.message = message; // 如果有传message，则使用所传的message
                }
                /* 
                    解决如何把toast里面的template放到body里面，这个时候就可以用到el这个属性，
                    但是如果直接把toast组件里面的el这个属性，再安装插件的时候赋给body是没有用的，这个时候toast还没有渲染出来，那么如何解决呢
                    就是install方法里面来手动挂载组件,创建一个构造器，然后new那个构造器，
                    创建出一个组件对象，然后把这个对象挂载到一个div那里，然后才把内容赋给body，做好把这个构造出来的对象付给原型 
                */
                let TempToastConstructor = Vue.extend(MmToast); // 创建一个TempToastConstructor组件
 
                instance = new TempToastConstructor({
                    data: opt
                });
                instance.vm = instance.$mount();
                //没有挂载到任何文档中，模板将被渲染为文档之外的的元素，并且你必须使用原生DOM API把它插入文档中。该方法返回实例自身。
                // console.log(instance.vm === instance)  // 输出为true
                document.body.appendChild(instance.vm.$el); //el对应的就是组件的dom元素
                instance.vm.toshowDia = showToast = true;
                instance.toSureCode = function () {
                    checkauth(instance.vm.form).then(d=>{
                        if(d.code == '0000' && d.data == true){
                            successmsg('安全码正确')
                            instance.vm.toshowDia = showToast = false;
                            resolve();
                            // this.toshowDia = false
                        }else if(d.data == false){
                            failmsg('安全码错误，操作失败')
                        }
                       
                    }).catch(e=>{
                        console.log('安全认证接口调用失败');
                    })
                    // if (!instance.vm.form.password) return Message.error('请输入安全码！');
                    // resolve(instance.vm.form);
                    // instance.vm.toshowDia = showToast = false;
                };
 
                instance.closeDia = function () {
                    reject();
                    instance.vm.toshowDia = showToast = false;
                    instance.vm.form = {
                        password: ''
                    };
                };
            });
        };
    }
};
export default saveCode;